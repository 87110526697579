import '../../styles/pages/spring-summer-2021/workplace-lessons-from-the-pandemic.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
// import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2021-stories/more-stories"
import ReactFullpage from '@fullpage/react-fullpage';
import Icon from "../../components/icons"
import { Preloader, Placeholder } from 'react-preloading-screen';
import { SwishSpinner } from "react-spinners-kit";
import TextPDF from '../../components/spring-summer-2021-stories/text-pdf';

import socialBanner from "../../images/spring-summer-2021/social-banners/workplace-lessons-from-the-pandemic.jpg";

import title from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/title-graphic.svg";


import slide2Strength from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/slide2-strength.jpg";
import slide3HybridWorkplace from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/slide3-hybrid-workplace.jpg";
import slide4Reimagine from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/slide4-reimagine.jpg";
import slide5BigData from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/slide5-big-data.jpg";
import slide5BigDataFlipped from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/slide5-big-data-flipped.jpg";
import slide6Unexpected from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/slide6-unexpected.jpg";
import slide7Digitization from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/slide7-digitization.jpg";
import slide8People from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/slide8-people.jpg";
import slide9Future from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/slide9-future.jpg";

import aprilMorris from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/april-morris.jpg";
import chiranjeevKohli from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/chiranjeev-kohli.jpg";
import jenniferChandler from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/jennifer-chandler.jpg";
import joshuaDorsey from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/joshua-dorsey.jpg";
import kleinjans from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/kleinjans.jpg";
import ofirTurel from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/ofir-turel.jpg";
import seanHsu from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/sean-hsu.jpg";
import vivekMande from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/vivek-mande.jpg";
import yinfeiKong from "../../images/spring-summer-2021/workplace-lessons-from-the-pandemic/yinfei-kong.jpg";

const pluginWrapper = () => {
  require('../../scripts/fullpage.responsiveSlides.min.js');
  // require('../scripts/fullpage.scrollHorizontally.min.js');
};

export default class Index extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      headerHide: false
    };
  }

  render() {
    var pageTitle = '8 Workplace Lessons From the Pandemic';
    var pageDesc = 'Business faculty share how the ‘black swan’ event is changing American enterprise.';
    var slug = 'workplace-lessons-from-the-pandemic';

    return (
      <Layout locationInfo={this.props.location} headerHide={this.state.headerHide} issue="spring-summer-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>

        <Preloader fadeDuration={500} style={{backgroundColor: '#111'}}>

        <ReactFullpage
          id="story-content"
          licenseKey={'74BC1699-BD29452E-B9391E28-1CBD2120'}
          // menu="#menu"
          pluginWrapper={pluginWrapper}
          normalScrollElements={'#mainNav'}
          loopBottom={true}
          navigation={false}
          slidesNavigation={false}
          controlArrows={false}
          responsiveSlides={true}
          responsiveSlidesKey={'ZnVsbGVydG9uLmVkdV85MzFjbVZ6Y0c5dWMybDJaVk5zYVdSbGN3PT00Zkk='}
          onLeave ={(origin, destination, direction) => {

            if (direction === 'down') {
              this.setState({headerHide: true});
            } else {
              this.setState({headerHide: false});
            }

          }}
          responsiveWidth={'769'}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <div className="section section1">
                <TextPDF slug={slug} />
                  <div className="text">
                    
                    <h1><img src={title} alt="8 Workplace Lessons From the Pandemic" /></h1>
                    <span className="sub-title">Business faculty share how the ‘black swan’ event is changing American enterprise.</span>
                    <span className="byline">By Karen Lindell</span>

                    <p><span className="intro-text">A year into the COVID-19 pandemic,</span> what lessons have American business leaders learned? How have they changed company operations and what will the workplace look like post-pandemic? </p>

                    <p>Faculty from Cal State Fullerton’s College of Business and Economics weighed in on these questions, and their answers centered on three areas: building resilience, embracing technology and navigating changes in relationships.</p>

                    <p>The experts also shared what they’ve been reading while working from home this past year.</p>

                    <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                      <Icon name="arrow-down" alt="Read Story" class="lg" />
                    </button>

                  </div>
                </div>
                <div className="section section2">
                  <div className="image-left" style={{backgroundImage: `url(${slide2Strength})`}} />
                  <div className="image-right" style={{backgroundImage: `url(${slide2Strength})`}} />
                  <div className="text">

                    <div className="text-alignment">
                      <h2>Gain Strength From<br />‘Black Swan’ Events</h2>
  
                      <p><span className="intro-text">“This pandemic is a ‘black swan’ event</span> — something you don’t prepare for because you didn’t expect it. Stressing any system is good to create anti-fragility. Mohammad Habibi, assistant professor of marketing, and I wrote a paper on how companies can use drastic economic downturns to come out stronger. The cliché, ‘What doesn’t kill you makes you stronger,’ turns out to be true.” </p>
  
                      <div className="faculty-member">
                        <img src={chiranjeevKohli} alt="" className="faculty-image" />
                        <div className="faculty-text">
                          <span className="faculty-name">Chiranjeev Kohli</span>
                          <span className="faculty-title">professor of marketing</span>
                        </div>
                      </div>
  
                      <p className="recommended-reading">Recommended reading:<br />“Caste: The Origins of Our Discontents” by Isabel Wilkerson; “The WEIRDest People in the World: How the West Became Psychologically Peculiar and Particularly Prosperous” by Joseph Henrich; and “The Tyranny of Merit: What's Become of the Common Good?” by Michael J. Sandel</p>
                    </div>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                </div>
                <div className="section section3">
                  <div className="image-left" style={{backgroundImage: `url(${slide3HybridWorkplace})`}} />
                  <div className="image-right" style={{backgroundImage: `url(${slide3HybridWorkplace})`}} />
                  <div className="text">

                    <div className="text-alignment">
                      <h2>Embrace a Hybrid Workplace</h2>
  
                      <p><span className="intro-text">“Remote and flexible work settings</span> seem to work just fine in many cases. Sometimes they work even better: online meetings rather than flying people in, or remote work instead of relocating employees to an expensive market. These experiences will likely push organizations to consider a different blend of local, remote and flexible job settings, a mix of face-to-face and virtual interactions, reduced reliance on office real estate and increased reliance on technology.”</p>

                      <div className="faculty-member">
                        <img src={ofirTurel} alt="" className="faculty-image" />
                        <div className="faculty-text">
                          <span className="faculty-name">Ofir Turel</span>
                          <span className="faculty-title">professor of information systems and decision sciences</span>
                        </div>
                      </div>
                      
                      <p className="recommended-reading">Recommended reading:<br />“The Hacking of the American Mind: The Science Behind the Corporate Takeover of Our Bodies and Brains” by Robert Lustig </p>
                    </div>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                </div>
                <div className="section section4">
                  <div className="image-left" style={{backgroundImage: `url(${slide4Reimagine})`}} />
                  <div className="image-right" style={{backgroundImage: `url(${slide4Reimagine})`}} />
                  <div className="text">

                    <div className="text-alignment">
                      <h2>Reimagine Leadership<br />and Organizations</h2>
                      
                      <p><span className="intro-text">“Business leaders have learned</span> the internal and external sides of agility. Internally, the pandemic taught us the true possibilities of telecommuting. But now leaders need to address new challenges like: Where does the organization begin and end if employees are not physically present? How do leaders manage remote teams? What does work-life balance mean if people work from home? Externally, leaders learned how to pivot amid uncertainty, but new questions have emerged: How can the firm survive changing laws and policies? How does it build resilience into the supply chain? How do we create ‘back-up’ revenue sources? Leaders have been given a chance to reimagine and reinvent our industries and organizations, and part of that will involve addressing these new questions.”</p>

                      <div className="faculty-member">
                        <img src={jenniferChandler} alt="" className="faculty-image" />
                        <div className="faculty-text">
                          <span className="faculty-name">Jennifer Chandler</span>
                          <span className="faculty-title">chair and associate professor of management</span>
                        </div>
                      </div>
  
                      <p className="recommended-reading">Recommended reading:<br />“A Paradise Built in Hell: The Extraordinary Communities That Arise in Disaster” by Rebecca Solnit </p>
                    </div>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                </div>
                <div className="section section5">
                  <div className="image-left" style={{backgroundImage: `url(${slide5BigData})`}} />
                  <div className="image-right" style={{backgroundImage: `url(${slide5BigDataFlipped})`}} />
                  <div className="text">

                    <div className="text-alignment">
                      <h2>Anticipate More Artificial<br />Intelligence, Big Data</h2>
                      
                      <p><span className="intro-text">“Working from home</span> and conducting business online will be the new normal, and such transactions will become smarter and more automated. This transition may facilitate the integration of artificial intelligence and big data analysis into business as virtual transactions give us more trackable and complete data.”</p>
  
                      <div className="faculty-member">
                        <img src={yinfeiKong} alt="" className="faculty-image" />
                        <div className="faculty-text">
                          <span className="faculty-name">Yinfei Kong</span>
                          <span className="faculty-title">associate professor of information systems and decision sciences</span>
                        </div>
                      </div>
                      
                      <p className="recommended-reading">Recommended reading:<br />“Big Data, Data Mining, and Machine Learning: Value Creation for Business Leaders and Practitioners” by Jared Dean and “An Introduction to Statistical Learning” by Gareth James, Daniela Witten, Trevor Hastie and Robert Tibshirani</p>
                    </div>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                </div>
                <div className="section section6">
                  <div className="image-left" style={{backgroundImage: `url(${slide6Unexpected})`}} />
                  <div className="image-right" style={{backgroundImage: `url(${slide6Unexpected})`}} />
                  <div className="text">

                    <div className="text-alignment">
                      <h2>Expect the Unexpected</h2>
  
                      <p><span className="intro-text">“Companies learned</span> that adapting is surviving. Firms such as Amazon were inherently well-equipped, although not immune from the need to adjust. Other models, such as those dependent upon brick-and-mortar locations or face-to-face interactions needed to make meaningful pivots for survival. Businesses also learned to expect the unexpected. Many have revised their business plans with an eye toward contingencies and anticipating unexpected events.”</p>
  
                      <div className="faculty-member">
                        <img src={joshuaDorsey} alt="" className="faculty-image" />
                        <div className="faculty-text">
                          <span className="faculty-name">Joshua D. Dorsey</span>
                          <span className="faculty-title">assistant professor of marketing</span>
                        </div>
                      </div>

                      <p className="recommended-reading">Recommended reading:<br />“The Art of War” by Sun Tzu</p>
                    </div>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                </div>
                <div className="section section7">
                  <div className="image-left" style={{backgroundImage: `url(${slide7Digitization})`}} />
                  <div className="image-right" style={{backgroundImage: `url(${slide7Digitization})`}} />
                  <div className="text">

                    <div className="text-alignment">
                      <h2>Jump on the Digitization Train</h2>
  
                      <p><span className="intro-text">“The pandemic has been a catalyst</span> for the enormous growth of e-commerce customer interaction, artificial intelligence, automation, remote working, telehealth and online education. Augmented reality has become an essential technology for retailers of furniture, cosmetics, jewelry and apparel. The pandemic has created a tipping point in business history, and those who miss these new opportunities will see their competitors joining the emerging resilient organizations in the next wave of growth.”</p>
  
                      <div className="faculty-member">
                        <img src={seanHsu} alt="" className="faculty-image" />
                        <div className="faculty-text">
                          <span className="faculty-name">Sean Hsu</span>
                          <span className="faculty-title">assistant professor of management</span>
                        </div>
                      </div>
  
                      <p className="recommended-reading">Recommended reading:<br />“Range: Why Generalists Triumph in a Specialized World” by David Epstein</p>
                    </div>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                </div>
                <div className="section section8">
                  <div className="image-left" style={{backgroundImage: `url(${slide8People})`}} />
                  <div className="image-right" style={{backgroundImage: `url(${slide8People})`}} />
                  <div className="text">
                    <div className="text-alignment">
                      <h2>Treat People Right:<br />It’s Good for Business</h2>
  
                      <p><span className="intro-text">“COVID-19 has shown business leaders</span> that policies aimed at improving people’s lives are also good for their company’s bottom line. It has also shown Americans that we ignore inequality and its consequences at our own peril. Mandated paid sick leave, health insurance and a livable minimum wage would level the playing field and disrupt the market advantage of companies who take advantage of their workers. Women have carried much of the burden the pandemic has placed on families. As a result, a record number of women have dropped out of the labor force, worsening staff shortages and costly employee turnover. Fortunately, women’s unemployment rates have recovered faster than expected. But the pandemic’s differential effects on women and men will increase gender inequality in income and labor market outcomes if they are not addressed.”</p>
  
                      <div className="faculty-member">
                        <img src={kleinjans} alt="" className="faculty-image" />
                        <div className="faculty-text">
                          <span className="faculty-name">Kristin Kleinjans</span>
                          <span className="faculty-title">associate professor of economics</span>
                        </div>
                      </div>

                      <p className="recommended-reading">Recommended reading:<br />“Deaths of Despair and the Future of Capitalism” by Anne Case and Angus Deaton</p>
                    </div>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                </div>
                <div className="section section9">
                  <div className="image-left" style={{backgroundImage: `url(${slide9Future})`}} />
                  <div className="image-right" style={{backgroundImage: `url(${slide9Future})`}} />
                  <div className="text">

                    <div className="text-alignment">
                      <h2>Prepare Future Employees<br />for the Post-Pandemic World</h2>
  
                      <p>“The pandemic has put a focus on the need for future employees to be equipped with technological resources, communication and critical thinking skills. With fewer face-to-face interactions, employers are going to expect a higher level of technological expertise and the ability to communicate very clearly about expectations from team members and clients. Through technology, we are now providing students with real business problems using real data, and they are learning critical thinking and analysis.”</p>

                      <div className="faculty-member">
                        <img src={vivekMande} alt="" className="faculty-image" />
                        <div className="faculty-text">
                          <span className="faculty-name">Vivek Mande</span>
                          <span className="faculty-title">chair and professor of accounting and director of the Center for Corporate Reporting and Governance</span>
                          <p className="recommended-reading">Recommended reading: “The End Is Always Near: Apocalyptic Moments from the Bronze Age Collapse to Nuclear Near Misses” by Dan Carlin</p>
                        </div>
                      </div>
                      
                      <div className="faculty-member">
                        <img src={aprilMorris} alt="" className="faculty-image" />
                        <div className="faculty-text">
                          <span className="faculty-name">April Morris</span>
                          <span className="faculty-title">lecturer in accounting and co-director of the Center for Corporate Reporting and Governance</span>
                          <p className="recommended-reading">Recommended reading: “Harry Potter and the Goblet of Fire” by J.K. Rowling</p>
                        </div>
                      </div>

                    </div>

                    <div className="nav-controls">
                      <button onClick={() => fullpageApi.moveSectionUp()} className="nav-button up">
                        <Icon name="arrow-up" alt="Previous Slide" />
                      </button>
                      <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down">
                        <Icon name="arrow-down" alt="Next Slide" />
                      </button>
                    </div>

                  </div>
                  
                  <p className="learn-more">
                  Support the College of Business and Economics<br /><a href="https://campaign.fullerton.edu/business-economics" target="_blank" rel="noopener noreferrer">campaign.fullerton.edu/business-economics</a>
                  </p>
                  
                </div>
                <div className="section fp-auto-height">
                  <MoreStories slug={slug} />
                </div>
              </ReactFullpage.Wrapper>
            );
          }}
        />
        
				<Placeholder>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <SwishSpinner size="30" frontColor="#dc8218" backColor="#00aeff" />
            <span style={{
              fontSize: '.7em',
              display: 'block', 
              marginTop: '30px', 
              color: '#ccc', 
              textTransform: 'uppercase',
              textAlign: 'center',
              letterSpacing: '5px'
            }}>
              Loading
            </span>
          </div>
				</Placeholder>
			</Preloader>

      </Layout>
    );
  }
} 